<template>
  <div class="content">
    <h1 class="page-title">
      {{ title }}<small class="text-muted">{{ titleEng }}</small>
    </h1>
    <div class="list-message">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="list-message-item d-flex justify-content-between align-items-center"
      >
        <div class="ms-2 me-auto">
          <font-awesome-icon icon="chevron-right" />
          <router-link :to="'/news/' + item.id">{{ item.subject }}</router-link>
        </div>
        <span class="text-primary text-nowrap" v-text="item.startDateString"></span>
      </div>
    </div>
    <div v-show="amt == 0" class="alert alert-secondary text-center">
      目前沒有任何資料
    </div>
    <pagination
      v-model="currectPage"
      :records="amt"
      :per-page="offset"
      :options="paginationOptions"
      @Paginate="clickPage"
    />
  </div>
</template>

<script>
import { PublicAPI } from '@/api/index'
import Pagination from 'v-pagination-3'

export default {
  components: {
    Pagination
  },
  emits: ['childSubject'],
  data() {
    return {
      items: [],
      amt: 0,
      currectPage: 1,
      offset: 10,
      paginationOptions: {
        theme: 'bootstrap4',
        texts: {
          count: '目前顯示第 {from} - {to} 筆資料，共 {count} 筆資料'
        }
      },
      title: this.$route.meta.nav,
      titleEng: this.$route.meta.navEng
    }
  },
  watch: {
    $route(to, from) {
      if (to.name != from.name) this.contentList()
    }
  },
  mounted() {
    this.contentList()
  },
  methods: {
    async contentList() {
      let loader = this.$loading.show()
      await PublicAPI.Messages(this.currectPage)
        .then(response => {
          this.amt = response.data.total
          this.currectPage = response.data.currectPage
          this.items = response.data.items
          // this.$emit('childSubject', this.content.subject)
        })
        .catch(err => {
          console.log(err)
        })
      loader.hide()
    },
    getLink(url, link) {
      return link == '' ? url : link
    },
    clickPage(selectedPage) {
      this.currectPage = selectedPage
      this.contentList()
    }
  }
}
</script>

<style>
.list-message {
  margin-bottom: 3rem;
  border-bottom: 1px solid #ccc;
}
.list-message-item {
  padding: 1rem 0.5rem;
}
.list-message-item + .list-message-item {
  border-top: 1px solid #ccc;
}
.list-message svg {
  margin-right: 1rem;
}
</style>
